import { useState } from "react";
import "../styles/Demo.css"

export default function Demo() {
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    return(
        <div className="demo-container">
            <div className='demo-navbar' id='navbar'>
                <a href='/'>
                    <div className='landing2-navbar-logo'>
                        <img 
                            src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/Screenshot+2023-09-04+at+2.03.46+PM-fotor-bg-remover-20230904213533+(1).png'
                            className='landing2-navbar-logo-img'
                        />
                    </div>
                </a>

                {showMobileMenu 
                ? <div className='landing2-navbar-container-mobile'>
                    <div className='landing2-navbar-mobile-top'>
                        <a href='/'>
                            <div className='landing2-navbar-logo-mobile-navbar'>
                                <img
                                    src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/Screenshot+2023-09-04+at+2.03.46+PM-fotor-bg-remover-20230904213533+(1).png'
                                    className='landing2-navbar-logo-img-mobile-navbar'
                                />
                            </div>
                        </a>

                    <div className='landing2-navbar-icon-mobile' onClick={() => setShowMobileMenu(false)}>
                        <i class="fa-solid fa-times"></i>
                    </div>
                    </div>

                    <div className='landing2-navbar-items-mobile'>
                    <a href='/about-us' className='landing2-navbar-item'>
                        <div className='landing2-navbar-item'>
                        About us
                        </div>
                    </a>

                    <a href='/demo' className='landing2-navbar-item'>
                        <div className='landing2-navbar-item'>
                        Demo
                        </div>
                    </a>

                    <a href='/support' className='landing2-navbar-item'>
                        <div className='landing2-navbar-item'>
                        Support
                        </div>
                    </a>

                    <a href='/careers' className='landing2-navbar-item'>
                        <div className='landing2-navbar-item'>
                            Careers
                        </div>
                    </a>

                    <a href='/login'>
                        <button className='landing2-navbar-login'>
                        Login
                        </button>
                    </a>

                    <a href='/demo'>
                        <button className='landing2-startbutton'>
                        Try Bobyard free <i class="fa-solid fa-angle-right"></i>
                        </button>
                    </a>
                    </div>
                </div>
                : <div className='landing2-navbar-icon-mobile' onClick={() => setShowMobileMenu(true)}>
                    <i class="fa-solid fa-bars"></i>
                </div>
                }

                <div className='landing2-navbar-items'>
                <a href='/about-us' className='landing2-navbar-item'>
                    <div className='landing2-navbar-item'>
                    About us
                    </div>
                </a>

                <a href='/demo' className='landing2-navbar-item'>
                    <div className='landing2-navbar-item'>
                    Demo
                    </div>
                </a>

                <a href='/support' className='landing2-navbar-item'>
                    <div className='landing2-navbar-item'>
                    Support
                    </div>
                </a>

                <a href='/careers' className='landing2-navbar-item'>
                    <div className='landing2-navbar-item'>
                        Careers
                    </div>
                </a>

                <a href='/login'>
                    <button className='landing2-navbar-login'>
                    Login
                    </button>
                </a>

                <a href='/demo'>
                    <button className='landing2-startbutton'>
                    Try Bobyard free <i class="fa-solid fa-angle-right"></i>
                    </button>
                </a>
                </div>
            </div>

            {/*<div className="demo-navbar">
                <a href='/'>
                    <div className='demo-navbar-logo'>
                        <img 
                            src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/Screenshot+2023-09-06+at+5.47.37+PM-fotor-bg-remover-20230906175036+(1).png'
                            className='landing2-navbar-logo-img'
                        />
                    </div>
                </a>
            </div>*/}

            <iframe
                src="https://calendly.com/d/cmm7-h2j-6jm/bobyard-demo"
                className="demo-iframe"
            ></iframe>
        </div>
    )
}